import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Alert as MuiAlert } from "@material-ui/lab";
import urlSlug from "url-slug";
import { EbooksService } from "../services";
import ReactQuill from "react-quill";
import { MFTextField } from ".";
import { spacing, SpacingProps } from "@material-ui/system";
import "react-quill/dist/quill.snow.css";
import "../styles/main.css";
import CodeAppComponent from "../components/CodeAppView";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";

import {
  getCategories,
  createCategory,
  deleteCategory,
} from "../services/categoryService";

import {
  Player,
  BigPlayButton,
  ControlBar,
  PlaybackRateMenuButton,
  LoadingSpinner,
} from "video-react";

import { RichText } from ".";
import CreatableSelect from "react-select/creatable";


interface Category {
  id: string;
  title: string;
}

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

interface EbooksFormProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

export const EbooksForm: React.FC<EbooksFormProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage,
}) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory]: any = React.useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = React.useState("");
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [app, setApp] = React.useState("");
  const [thumbnail, setThumbnail] = React.useState("");
  const [path, setPath] = React.useState("");
  const [status, setStatus] = React.useState<ArrayObjectSelectState>({
    selectedStatusMethod: null,
  });

  const [category, setCategory] = React.useState<ArrayObjectSelectCategory>({
    selectedCategoryMethod: null,
  });
  const [statusForm, setStatusForm] = React.useState({ sent: false });

  React.useEffect(() => {
    setTitle(data?.title || "");
    setApp(data?.app || "");
    setThumbnail(data?.thumbnail || "");
    setPath(data?.path || "");
    status.selectedStatusMethod = {
      value: data?.status || "",
      label: data?.status || "",
    };
    category.selectedCategoryMethod = {
      value: data?.category || "",
      label: data?.category || "",
    };
  }, [data]);

  const resetForm = () => {
    setTitle("");
    setApp("");
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    const formData = {
      title,
      app,
      slug_name: urlSlug(title),
      status: status.selectedStatusMethod?.value,
      category: category.selectedCategoryMethod?.label || "",
    };


    setSubmitting(true);

    try {
      console.log(formData);
      await handleSubmit(formData);
      if (resetFields) {
      }
      setStatusForm({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);
      }

      setStatusForm({ sent: false });
      setSubmitting(false);
    }
  };

  interface StatusMthod {
    label: string;
    value: string;
  }

  interface StatusCategory {
    label: string;
    value: string;
  }
  interface ArrayObjectSelectState {
    selectedStatusMethod: StatusMthod | null;
  }
  interface ArrayObjectSelectCategory {
    selectedCategoryMethod: StatusCategory | null;
  }
  const statusMthod: StatusMthod[] = [
    {
      label: "Público",
      value: "Público",
    },
    {
      label: "Privado",
      value: "Privado",
    },
  ];

  const onTitleChange = (event: any) => setTitle(event.target.value);
  const onCodeAppChange = (event: any) => {
    setApp(event.target.value);
  };


  const fetchCategories = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await getCategories();
      setCategories(data);
    } catch (error) {
      setError("Erro ao buscar categorias.");
    } finally {
      setIsLoading(false);
    }
  };


  const handleDeleteCategory = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Previne o comportamento padrão do botão

    const selectedCategoryId = category?.selectedCategoryMethod?.value;

    if (!selectedCategoryId) {
      alert("Nenhuma categoria selecionada para exclusão!");
      return;
    }

    const confirmDelete = window.confirm(
      "Tem certeza que deseja excluir esta categoria?"
    );

    if (!confirmDelete) return;

    try {
      await deleteCategory(selectedCategoryId);

      // Atualiza a lista localmente
      setCategories((prevCategories) =>
        prevCategories.filter((cat: any) => cat.value !== selectedCategoryId)
      );

      setCategory({ selectedCategoryMethod: null });
      alert("Categoria deletada com sucesso!");
      fetchCategories();
    } catch (error) {
      setError("Erro ao deletar categoria.");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          {formTitle}
        </Typography>
        {statusForm && statusForm.sent && (
          <Alert severity="success">{successMessage}</Alert>
        )}

        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <Grid container spacing={8} direction={"row"}>
              <Grid item xs={6}>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <Grid item xs={8} style={{ marginBottom: "16px" }}>
                    <MFTextField
                      error={!!errors?.title}
                      fullWidth={true}
                      helperText={errors?.title}
                      label="Título"
                      my={2}
                      name="title"
                      onChange={onTitleChange}
                      placeholder="Título do vídeo"
                      value={title}
                      variant="outlined"
                    />
                    <MFTextField
                      fullWidth={true}
                      label="Código de Atualização"
                      my={2}
                      name="código"
                      onChange={onCodeAppChange}
                      placeholder="Código"
                      value={app}
                      variant="outlined"
                    />
                    <br />
                    <Select
                      onChange={(option: StatusMthod | null) => {
                        setStatus({ selectedStatusMethod: option });
                        setSelectedCategory(option);
                      }}
                      value={status.selectedStatusMethod}
                      getOptionLabel={(statusMthod: StatusMthod) =>
                        statusMthod.label
                      }
                      getOptionValue={(statusMthod: StatusMthod) =>
                        statusMthod.value
                      }
                      options={statusMthod}
                      placeholder="Selecione"
                      isClearable={true}
                      backspaceRemovesValue={true}
                      id="status"
                      name="status"
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          marginBottom: "10px",
                        }),
                      }}
                    />
                    {isLoading || isCreating ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <CreatableSelect
                          onChange={(option: StatusCategory | null) => {
                            setCategory({ selectedCategoryMethod: option });
                          }}
                          onCreateOption={async (inputValue: string) => {
                            try {
                              await createCategory(inputValue);
                              fetchCategories();
                            } catch (error) {
                              console.error("Erro ao criar categoria:", error);
                            }
                          }}
                          value={category.selectedCategoryMethod}
                          getOptionLabel={(category: StatusCategory) => category.label}
                          getOptionValue={(category: StatusCategory) => category.value}
                          options={categories.map((category) => ({
                            label: category.title,
                            value: category.id,
                          }))}
                          placeholder="Selecione ou crie uma categoria"
                          isClearable
                          backspaceRemovesValue
                        />
                        {categories && (
                          <button
                            onClick={handleDeleteCategory}
                            disabled={!category?.selectedCategoryMethod} // Desabilita o botão se nenhuma categoria estiver selecionada
                            style={{
                              marginTop: "10px",
                              backgroundColor: !category?.selectedCategoryMethod ? "gray" : "red", // Cor quando desabilitado
                              color: "white",
                              border: "none",
                              padding: "10px 20px",
                              cursor: !category?.selectedCategoryMethod ? "not-allowed" : "pointer", // Cursor para desabilitado
                            }}
                          >
                            Excluir Categoria
                          </button>
                        )}
                      </>
                    )}

                    {error && (
                      <Typography color="error" mt={3}>
                        {error}
                      </Typography>
                    )}
                  </Grid>
                  <Button
                    onClick={submitForm}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={4}
                  >
                    {buttonName}
                  </Button>
                </form>
              </Grid>
              <Grid item xs={6}>
                <Player height={10} playsInline poster={thumbnail} src={path}>
                  <BigPlayButton position="center" />
                  <LoadingSpinner />
                  <ControlBar>
                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                  </ControlBar>
                </Player>
              </Grid>
            </Grid>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
};
