import React from "react";
import { ProtectedPage, EbooksForm } from "../../components";
import { EbooksService } from "../../services";
import { useParams } from "react-router-dom";

export const EbooksRegisterFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await EbooksService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await EbooksService.update(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Ebook"
      breadcrumbs={[
        { name: "Ebooks", to: "/ebooks" },
        { name: "Editar Ebook", to: "/ebooks/:id/edit" },
      ]}
    >
      <EbooksForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar Ebook"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Ebook Editado com Sucesso!"
      />
    </ProtectedPage>
  );
};
