import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import axios from "axios";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";

import {
  Add as AddIcon,
  ListSharp,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Link as FileCopyIcon,
  VisibilityOutlined,
  VisibilityOff,
} from "@material-ui/icons";
import { ProtectedPage, CardEbooksComponent } from "../../components";
import { EbooksService } from "../../services";

import { height, spacing, width } from "@material-ui/system";

import "../../styles/main.css";

import Swal from "sweetalert2";
import EbooksViewCount from "../../components/EbooksViewCount";
import CodeAppComponent from "../../components/CodeAppView";
import {
  getCategories,
} from "../../services/categoryService";

const Paper = styled(MuiPaper)`
  padding: 16px;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

let id = 0;

function EnhancedTable({ ebooks }) {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState({});
  const [deleteId, setDeleteId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const hiddenFilePictureInput = React.useRef(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRemove = async () => {
    await EbooksService.deleteById(deleteId);
    handleClose();
    const resList = await EbooksService.list(page + 1, 6);
    console.log(resList.data);
    setData(resList.data);
  };

  const handleRemoveAction = (id) => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await EbooksService.list(page + 1, 6);
      setData(resList.data);
    })();
  }, [page]);

  const handlePictureClick = (e, identification) => {
    id = identification;
    hiddenFilePictureInput.current.click();
  };

  const onFilePictureChange = (e) => {
    let files = e.target.files;
    let fileReader = new FileReader();

    fileReader.readAsDataURL(files[0]);

    fileReader.onload = () => {
      submitPictureForm(files[0], id);
    };
  };

  const submitPictureForm = async (file, id) => {
    let formData = new FormData();
    formData.append("fileToUpload", file);

    let endpoint = "https://files.minhafono.com.br/upload-pictures.php";
    axios
      .post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res) => {
        Swal.fire({
          icon: "success",
          title: "Foto de capa atualizada!",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        await EbooksService.update(id, {
          thumbnail: "https://files.minhafono.com.br/" + res.data,
        });
        const resList = await EbooksService.list(page + 1, 6);
        setData(resList.data);
      });
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Paper>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{alert!}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Tem certeza que deseja excluir?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRemove} color="primary">
                Sim
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus>
                Não
              </Button>
            </DialogActions>
          </Dialog>
          <TableContainer className="tableContainer">
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className="tableHeadCell">Foto</TableCell>
                  <TableCell className="tableHeadCell">Ebook</TableCell>
                  <TableCell className="tableHeadCell">Visibilidade</TableCell>
                  <TableCell className="tableHeadCell">Visualizações</TableCell>
                  <TableCell className="tableHeadCell">Código</TableCell>
                  <TableCell className="tableHeadCell">Data</TableCell>
                  <TableCell className="tableHeadCell">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ebooks.slice(page * 6, page * 6 + 6).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">
                      <form className="hiddenForm">
                        <input
                          type="file"
                          name="file"
                          onChange={onFilePictureChange}
                          ref={hiddenFilePictureInput}
                        ></input>
                      </form>
                      <Customer
                        onClick={(e) => handlePictureClick(e, row.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <CardEbooksComponent url={row?.thumbnail} />
                      </Customer>
                    </TableCell>
                    <TableCell className="tableCell">
                      <Customer>
                        <Typography>
                          <strong>{row?.title} <span style={{ color: "#7ecde2", textTransform: "uppercase" }}>
                            {row?.category}
                          </span></strong>
                          <br />
                          <small>
                            {row?.description?.length <= 25 ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: row?.description,
                                }}
                              />
                            ) : (
                              <span title={row?.description}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: row?.description?.substr(0, 200),
                                  }}
                                />
                              </span>
                            )}
                          </small>
                        </Typography>
                      </Customer>
                    </TableCell>
                    <TableCell className="tableCell">
                      <Typography className="main">
                        {row?.status === "Público" ? (
                          <VisibilityOutlined color="primary" />
                        ) : (
                          <VisibilityOff color="error" />
                        )}{" "}
                        &nbsp; {row?.status}
                      </Typography>
                    </TableCell>
                    <TableCell className="tableCell">
                      <Typography>
                        <EbooksViewCount ebookId={row.id} />
                      </Typography>
                    </TableCell>
                    <TableCell className="tableCell">
                      <Typography>{row?.app}</Typography>
                    </TableCell>
                    <TableCell className="tableCell">
                      <Typography>
                        <small>
                          {row?.createdAt}
                          <br />
                          Publicado
                        </small>
                      </Typography>
                    </TableCell>
                    <TableCell className="actionsCell">
                      <IconButton
                        aria-label="edit"
                        component="a"
                        href={`https://leitor-pdf.web.app/read?url=${row.path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FileCopyIcon />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        component={NavLink}
                        to={`/ebooks/${row.id}/edit`}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleRemoveAction(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={data?.totalResults}
            rowsPerPage={6}
            page={page}
            onChangePage={handleChangePage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export const EbooksIndexPage = () => {
  const [selectedCategory, setSelectedCategory] = React.useState("Todos");
  const [ebooks, setEbooks] = React.useState([]);
  const [categories, setCategories] = React.useState<string[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        const data = await getCategories();
        console.log(data);

        // Mapeia os títulos das categorias e adiciona o valor "Todos" no início
        const formattedCategories = ["Todos", ...data.map((category: any) => category.title)];

        setCategories(formattedCategories);
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    })();
  }, []);


  const filterEbooks = (section) => {
    if (section === "Todos") return ebooks;
    return ebooks.filter((ebook: any) => ebook?.category?.startsWith(section));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await EbooksService.list(); // Assumindo que retorna todos os dados
      setEbooks(response.data.results || []);
    };

    fetchData();
  }, []);

  const filteredEbooks = filterEbooks(selectedCategory);

  const hiddenFileVideosInput = React.useRef(null);

  const handleVideosClick = () => {
    hiddenFileVideosInput.current.click();
  };

  const onFileVideoChange = (e) => {
    let files = e.target.files;
    let fileReader = new FileReader();

    fileReader.readAsDataURL(files[0]);

    fileReader.onload = () => {
      submitVideoForm(files[0]);
    };
  };

  const submitVideoForm = async (file) => {
    const toastLoad = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      iconColor: "white",
      customClass: { popup: "colored-toast" },
      timerProgressBar: true,
    });

    let formData = new FormData();
    formData.append("fileToUpload", file);

    let endpoint = "https://files.minhafono.com.br/upload-ebooks.php";

    axios
      .post(endpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          toastLoad.fire({
            icon: "info",
            title: "Carregando vídeo... " + percentCompleted + "%",
          });
        },
      })
      .then((res) => {
        toastLoad.close();

        Swal.fire({
          icon: "success",
          title: "Ebook carregado com sucesso!",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        EbooksService.create({
          path: "https://files.minhafono.com.br/" + res.data,
          status: "Privado",
        }).then(() => {
          window.location.reload();
        });
      });
  };

  return (
    <ProtectedPage
      title="Ebooks"
      breadcrumbs={[{ name: "Ebooks", to: "/ebooks" }]}
      action={
        <Button
          onClick={handleVideosClick}
          color="primary"
          startIcon={<AddIcon />}
          variant="contained"
        >
          Enviar Ebook
        </Button>
      }
    >
      <form className="hiddenForm">
        <input
          type="file"
          name="file"
          onChange={onFileVideoChange}
          ref={hiddenFileVideosInput}
        ></input>
      </form>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            variant="outlined"
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          <EnhancedTable ebooks={filteredEbooks} />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};