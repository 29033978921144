import axios from "axios";


const API_URL = "https://pro-fono-backend.herokuapp.com/front/ebooks-category";
const POST_URL = "https://pro-fono-backend.herokuapp.com/backoffice/ebooks-category";



export const getCategories = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    throw new Error("Erro ao buscar categorias");
  }
};


export const createCategory = async (newCategory: string) => {
  try {
    const response = await axios.post(POST_URL, { title: newCategory });
    return response.data;
  } catch (error) {
    throw new Error("teste");
  }
};

export const deleteCategory = async (id: string) => {
  if (!id) throw new Error("ID inválido para exclusão");

  try {
    await axios.delete(`${POST_URL}/${id}`);
    return true;
  } catch (error: any) {
    console.error("Erro ao excluir a categoria:", error);
    throw new Error("Erro ao excluir a categoria"); // Propaga o erro para ser tratado no handler
  }
};

